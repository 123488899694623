import { Heading } from "@chakra-ui/react"
import { Row } from "antd";
import { Fragment, useEffect, useState } from "react"
import PaginationComp from "../../../Layouts/Pagination";
import TableComp from "../../../Layouts/Table";
import IRIFilters from "./IRIFilters";

const IndiceRugosidad = () => {
    const [filters, setFilters] = useState({});
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {

    }
    const searchCF = () => {

    }

    const changePage = () => {
        setPage(page);
        setPageSize(pageSize);
    }
    return (
        <Fragment>
            <Heading>Indice de Ruosidad Internacional</Heading>
            {/* Filtro: Para realizar consultas específicas */}
            <Row justify='end' className='mb-2'>
                <IRIFilters
                    filters={filters}
                    setFilters={setFilters}
                    searchCF={searchCF}
                />
            </Row>
            {/* Tabla de registros */}
            <TableComp
                headers={['#', 'Código', 'Operador', 'Segmento', 'Estado', 'Creado en', 'Acciones']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
            />
            {/* Paginación */}
            <PaginationComp current={page} totalItems={totalItems} onChange={changePage} />
        </Fragment>
    )
}

export default IndiceRugosidad