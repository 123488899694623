import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/react"
import { Container } from "react-bootstrap"
import TabsComp from "../../Layouts/Tabs"
import CoeficienteFriccion from "./CF/Index"
import IndiceRugosidad from "./IRI/Index"
import ProfundidadRoderas from "./PR/Index"

const MaintenanceStandard = () => {
	return (
		<Container fluid>
			<TabsComp
				tablist={[
					'Indice de Rugosidad Internacional (IRI)',
					'Profundidad de Roderas (PR)',
					'Coeficiente de Fricción (CF)'
				]}
				tabpanels={[
					<IndiceRugosidad />,
					<ProfundidadRoderas />,
					<CoeficienteFriccion />
				]}
			/>
		</Container>
	)
}

export default MaintenanceStandard