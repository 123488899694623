import { Alert, AlertIcon, Heading } from "@chakra-ui/react";
import { PageHeader, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
// Librería SASS para efectos de Drag and Drop de Calendar
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { indexCedisDeliveries } from "../../api/Cedis/Deliveries";
import ModalView from "./fragments/ModalView";
import { indexCedisUsers } from "../../api/Cedis/CedisUsers";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
const { Option } = Select;

const CedisDeliveries = () => {
    const [events, setEvents] = useState([]);
    const [myState, setMyState] = useState({
        displayDragItemInCell: true
    });
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const [modalView, setModalView] = useState(false);
    const [eventSel, setEventSel] = useState(null);                 // Entrega seleccionada
    const [cedis_id, setCedisID] = useState(null);                  // Cedis seleccionado
    const [cedisAccess, setCedisAccess] = useState([]);             // Cedis a los que tiene acceso el usuario
    const dispatch = useDispatch();

    useEffect(async () => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/cedis/deliveries' });
        // Se obtienen los cedis a los que tiene acceso el usuario
        let response = await indexCedisUsers({ headers, user_id: login.user.id });
        setCedisAccess(response.data);
        // Si obtiene resultados, procede a buscar entregas para mostrarlas en el calendario// Si obtiene resultados, procede a buscar entregas para mostrarlas en el calendario
        if (response.data.length > 0) {
            setCedisID(response.data[0].cedis_id);
        }
    }, []);

    useEffect(() => {
        if (cedis_id)
            getEvents();
    }, [cedis_id]);

    // Abrir o cerrar modal
    const openOrCloseView = () => setModalView(!modalView);
    // Obtiene las entregas según el ID del CEDIS
    const getEvents = async () => {
        let response = await indexCedisDeliveries({ headers, cedis_id });
        setEvents(
            response.data.map((event) => {
                let { start, end } = event;
                return {
                    ...event,
                    start: new Date(start),
                    end: new Date(end)
                }
            })
        );
    }
    // Abrir modal al hacer doble click sobre un evento
    const doubleClick = (event) => {
        console.log("Double click: ", event);
        setEventSel(event);
        openOrCloseView();
    }
    // Evento para redimesionar el evento, es decir, cambiar rango de fechas del evento
    const resizeEvent = ({ event, start, end }) => {

        const nextEvents = events.map(existingEvent => {
            return existingEvent.id == event.id ?
                { ...existingEvent, start, end } : existingEvent
        });
        setEvents(nextEvents);
        // alert(`${event.title} was resized to ${start}-${end}`);
    }
    // Evento para mover el evento, es decir, hacer un drag al evento en el calendario
    const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        let allDay = event.allDay;
        if (!event.allDay && droppedOnAllDaySlot) allDay = true;
        else if (event.allDay && !droppedOnAllDaySlot) allDay = false;

        const nextEvents = events.map(existingEvent => {
            return existingEvent.id == event.id ?
                { ...existingEvent, start, end, allDay } : existingEvent
        });
        setEvents(nextEvents);
        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    }
    // Detecta si el evento a sido arrastrado afuera
    const dragFromOutsideItem = () => {
        return myState.draggedEvent;
    }
    // Evento para detectar si se empieza a mover un evento
    const handleDragStart = event => {
        setMyState({ draggedEvent: event });
    }
    // Evento para eliminar evento al arrastrarlo hacia afuera
    const onDropFromOutside = ({ start, end, allDay }) => {
        const { draggedEvent } = myState;
        const event = {
            id: draggedEvent.id,
            title: draggedEvent.title,
            start,
            end,
            allDay: allDay,
        }

        setMyState({ draggedEvent: null })
        moveEvent({ event, start, end })
    }
    // Evento vacío
    const newEvent = (_event) => { }
    // Cambiar de centro de distribución
    const changeCedis = (value) => { setCedisID(value); }
    return (
        <Fragment>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>Entregas</Heading>}
                extra={[
                    <Select placeholder='CEDIS' onChange={changeCedis} value={cedis_id} name='cedis_id' style={{ width: 250 }}>
                        {
                            cedisAccess.map((item, index) =>
                                <Option key={index} value={item.cedis_id}>{item.cedis_code} - {item.cedis_name}</Option>
                            )
                        }
                    </Select>
                ]}
            >
            </PageHeader>
            {
                cedisAccess.length === 0 &&
                <Alert status='warning'>
                    <AlertIcon />
                    Aún no ha sido asignado a un Centro de Distribución (CEDIS)
                </Alert>
            }
            {/* Componente de calendario */}
            <DragAndDropCalendar
                selectable
                localizer={localizer}
                events={events}
                onEventDrop={moveEvent}
                resizable
                onEventResize={resizeEvent}
                onSelectSlot={newEvent}
                onDragStart={console.log}
                style={{ height: 500 }}
                onDoubleClickEvent={doubleClick}
                dragFromOutsideItem={
                    myState.displayDragItemInCell ? dragFromOutsideItem : null
                }
                onDropFromOutside={onDropFromOutside}
                handleDragStart={handleDragStart}
            />
            {/* Modal para ver lista detallada del evento seleccionado */}
            {
                modalView && <ModalView
                    onClose={openOrCloseView}
                    event={eventSel}
                    headers={headers}
                />
            }
        </Fragment>
    );
};


export default CedisDeliveries;