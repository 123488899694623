import { useToast } from '@chakra-ui/react';
import { faArrowLeft, faBackward, faForward, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storeBusinessNameQuery } from '../../../api/Cfdi/BusinessNameQueries';
import { request_struct } from '../validate/Request';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal'
import FormRequest from './FormRequest';
import SelectBusinessNames from './SelectBusinessNames';

const NewRequest = (props) => {
    const { onClose, headers, update, fibra_id } = props;
    const [t, i18n] = useTranslation('bulk_download');
    const toast = useToast();
    const p = 'modal-request';
    const pt = `${p}.toasts`;
    const [data, setData] = useState({
        start_time: '00:00',
        end_time: '23:59'
    });
    const [viewForm, setViewForm] = useState(false);    // Ver lista de razones sociales o formulario para la petición
    const [businessNamesSel, setBusinessNamesSel] = useState([]);       // Razones sociales seleccionadas
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (businessNamesSel.length > 0) setDisabled(false); else setDisabled(true);
    }, [businessNamesSel]);
    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        for (let index = 0; index < request_struct.length; index++) {
            const { key, name } = request_struct[index];
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({ toast, title: t(`${pt}.${name}.title`), description: t(`${pt}.${name}.description`), status: 'warning' });
                break;
            }
        }
        return response;
    }

    const makeRequest = async () => {
        if (validate()) {
            const { download_type, request_type, start_date, start_time, end_date, end_time, rfc } = data;
            let _data = {
                business_name_ids: businessNamesSel, // Se mandan todos los identificadores de las razones sociales que fueron seleccionados
                download_type,
                request_type,
                start_date: start_date + ' ' + start_time,
                end_date: end_date + ' ' + end_time
            };
            if (rfc) _data.rfc = rfc
            let response = await storeBusinessNameQuery({
                headers,
                data: _data
            });
            if (response) {
                setToast({ toast, title: t(`${pt}.success.title`), description: t(`${pt}.success.description`), status: 'success' });
                update();
                onClose();
            } else {
                setToast({ toast, title: t(`${pt}.failure.title`), description: t(`${pt}.failure.description`), status: 'error' });
            }
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={viewForm ? t(`${p}.title`) : t(`${p}.title2`)}
            size='lg'
            body={<Fragment>
                {
                    viewForm ?
                        <FormRequest
                            handleChange={handleChange}
                        /> :
                        <SelectBusinessNames
                            headers={headers}
                            fibra_id={fibra_id}
                            businessNamesSel={businessNamesSel}
                            setBusinessNamesSel={setBusinessNamesSel}
                        />
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${p}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                {
                    viewForm ? <Fragment>
                        <ButtonIcon name={t(`${p}.btn-backward`)} icon={faBackward} variant='outline-primary' onClick={() => setViewForm(false)} />
                        <ButtonIcon name={t(`${p}.btn-query`)} icon={faPaperPlane} variant='outline-success' onClick={makeRequest} />
                    </Fragment> : <Fragment>
                        <ButtonIcon name={t(`${p}.btn-forward`)} icon={faForward} variant='outline-primary' onClick={() => setViewForm(true)} disabled={disabled} />
                    </Fragment>
                }
            </Fragment>}
        />
    )
}

export default NewRequest