import '../../styles/Home.Component.css';
import { Fragment } from "react";
import { Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import { Carousel } from 'antd';

import _dashboard from '../../images/home/1. dashboard.png';
import _mailbox from '../../images/home/2. mailbox.png';
import _incidents from '../../images/home/3. incidents.png';
import _repse from '../../images/home/4. repse.png';
import _deliveries from '../../images/home/5. deliveries.png';
import _purchase from '../../images/home/6. purchase-orders.png';

// const contentStyle = {
//     height: '70vh',
//     color: '#fff',
//     lineHeight: '160px',
//     textAlign: 'center',
//     background: '#364d79',
// };

const Home = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <Fragment>
            <Heading>{t("home.title")}</Heading>
            <Text className="mb-3 mt-3">
            {t("home.description")}
            </Text>
            {/* <Carousel autoplay>
                <div><img src={_dashboard} /></div>
                <div><img src={_mailbox} /></div>
                <div><img src={_incidents} /></div>
                <div><img src={_repse} /></div>
                <div><img src={_deliveries} /></div>
                <div><img src={_purchase} /></div>
            </Carousel> */}
        </Fragment>
    );
}

export default Home;