import { useToast } from '@chakra-ui/react';
import { faArrowLeft, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateAuthCxpCompPago } from '../../../api/ERP/SAP/AuthCxpCompPago';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'
import { dataAuthCxpCompPago } from '../validate/AuthCxpCompPago';

const AuthCxpCompPago = (props) => {
    const { onClose, headers } = props;
    const [data, setData] = useState({});
    const toast = useToast();
    const [t, i18n] = useTranslation('erp');
    const p = 'AuthCxpFacProv.form';
    const pt = 'AuthCxpFacProv.toasts';

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        for (let index = 0; index < dataAuthCxpCompPago.length; index++) {
            const { key, name } = dataAuthCxpCompPago[index];
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({ toast, title: t(`${pt}.${name}.title`), description: t(`${pt}.${name}.description`), status: 'warning' });
                break;
            }
        }
        return response;
    }
    const authFac = async () => {
        if (validate()) {
            try {
                let response = await updateAuthCxpCompPago({ headers, data });
                setToast({ toast, title: 'Respuesta de la petición: ', description: Object.values(response.data[0])[0], status: 'warning' });
            } catch (error) {
                setToast({ toast, title: '¡Error!', description: 'Error al procesar la solicitud', status: 'error' });
            }
        }
    }

    return (
        <ModalComp
            title={t('AuthCxpCompPago.title')}
            size='lg'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.user`)} name='IUsuario' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.uuid`)} name='IUuid' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.status`)} name='IEstatus' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label>{t(`${p}.message`)}</Form.Label>
                        <textarea className='form-control' name='IMensaje' onChange={handleChange} />
                    </Col>
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${p}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t(`${p}.btn-save`)} icon={faUserCheck} variant='outline-success' onClick={authFac} />
            </Fragment>}
        />
    )
}

export default AuthCxpCompPago