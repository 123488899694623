import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getCfdiStatuses } from "../../api/Cfdi/CfdiStatuses";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Mailbox from "./fragments/Mailbox"
import ModalCfdi from "./fragments/ModalCfdi";

const MailboxAdmin = () => {
    const login = useSelector(state => state.login);
    const { fibra, role_selected, roles, token } = login;
    const headers = { Authorization: `Bearer ${token}` };

    const [mailbox, setMailbox] = useState([]);             // Lista de CFDi's
    const [modalCfdi, setModalCfdi] = useState(false);      // Modal para cambiar permisos CFDI
    const [invoice, setInvoice] = useState(null);           // Factura seleccionada para mostrar en el modal
    const [cfdiStatuses, setCfdiStatuses] = useState([]);   // Estados CFDi de la fibra
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [t, i18n] = useTranslation('global');
    const [filters, setFilters] = useState({ cfdi_status_id: null });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mailbox/admin' });
    }, []);

    useEffect(() => {
        getCfdis();
        getMailbox();
    }, [page, pageSize]);

    const getMailbox = async () => {
        setLoading(true);
        let role_id = role_selected.id;
        if (!role_id) {
            if (roles.length > 0) role_id = roles[0].id

        }
        let params = { headers, page, pageSize, ...filters };
        let response = await getInvoices({
            ...params, fibra_id: fibra.id,
            role_id
        });
        setMailbox(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const openOrCloseCfdi = () => setModalCfdi(!modalCfdi);
    const changeStatus = (item) => {
        setInvoice(item);
        openOrCloseCfdi();
    }

    // Método que obtiene los estados CFDi que pertenecen a la fibra
    const getCfdis = async () => {
        let response = await getCfdiStatuses({ headers, fibra_id: fibra.id });
        setCfdiStatuses(response);
    }
    // Buscar coincidencias de cadena
    const searchInvoice = () => {
        setPage(1);
        getMailbox();
    }

    return (
        <Fragment>
            <Mailbox
                is_admin
                mailbox={mailbox}                   // Colección de registros obtenidos
                loading={loading}                   // Indicador de carga de registros de facturas
                invoice={invoice}                   // Factura seleccionada
                setInvoice={setInvoice}             // Factura seleccionada
                page={page}                         // Página actual
                setPage={setPage}                   // Método para cambiar la página
                pageSize={pageSize}                 // Tamaño de la página
                setPageSize={setPageSize}           // Método para cambiar el tamaño de la página
                totalItems={totalItems}             // Total de registros existentes
                tactions={[                          // Botones que son visibles para el administrador
                    {
                        handleClick: changeStatus, icon: faBoxes, tooltip: t('mailbox.table.tooltips.change-cfdi'), variant: 'outline-secondary'
                    }
                ]}
                searchInvoice={searchInvoice}
                filters={filters}
                setFilters={setFilters}
            />

            {/* Modal para cambiar permisos CFDI */}
            {
                modalCfdi &&
                <ModalCfdi
                    onClose={openOrCloseCfdi}
                    invoice={invoice}
                    cfdiStatuses={cfdiStatuses}
                    headers={headers}
                    update={getMailbox}
                />
            }
        </Fragment>
    );
}

export default MailboxAdmin;