import Fetcher from "../../libs/Petition"

const url = '/invoices';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param role_id Se utiliza para identificar las facturas de los estados cfdi a los que tiene acceso
 * @param user_id Se obtienes las facturas enviadas por un usuario
 * @param page Se utiliza para conocer la página en la que se encuentra
 * @param pageSize Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param cfdi_status_id [Opcional] si se recibe este parámetro, se aplica el filtro sobre ese identificador
 * @param start_date [Opcional] Indica a partir de que fecha se comienza a realizar la búsqueda
 * @param end_date [Opcional] Indica hasta que fecha realizar la búsqueda
 * @returns Object {"data" => Invoice::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const getInvoices = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
        role_id,
        user_id,
        cfdi_status_id,
        folio,
        uuid,
        rfc_receptor,
        rfc_issuing,
        currency,
        start_date,
        end_date,
    } = props;
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { fibra_id, role_id, page, pageSize };
        // Parámetros opcionales
        if (cfdi_status_id && cfdi_status_id !== '-1') params.cfdi_status_id = cfdi_status_id;
        if (start_date && start_date !== '') params.start_date = start_date;
        if (end_date && end_date !== '') params.end_date = end_date;
        if (user_id && user_id !== '') params.user_id = user_id;
        if (folio && folio !== '') params.folio = folio;
        if (uuid && uuid !== '') params.uuid = uuid;
        if (rfc_issuing && rfc_issuing !== '') params.rfc_issuing = rfc_issuing;
        if (rfc_receptor && rfc_receptor !== '') params.rfc_receptor = rfc_receptor;
        if (currency && currency !== '') params.currency = currency;

        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Fetch invoice index: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param invoice_id Identificador de la factura sobre el que se hará la consulta
 * @returns Invoice Retorna un objeto tipo Invoice
 */
export const showInvoice = async ({ headers, invoice_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'GET',
            headers
        });
        console.log("Fetch show invoice: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeInvoice = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param invoice_id Identificador de la factura que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateInvoice = async ({ headers, data, invoice_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'PUT',
            data,
            headers
        });
        console.log("Update fetch invoice: ", fetch);
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param invoice_id Identificador de la factura que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyInvoice = async ({ headers, invoice_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

/**
 * 
 */
export const uploadInvoice = async ({ headers, data, params }) => {
    let response = false;
    // console.log("headers: ", headers);
    // console.log("data; ", data);
    // console.log("params: ", params);
    try {
        let fetch = await Fetcher({
            url: `${url}/upload`,
            method: 'POST',
            data, headers, params
        });
        if (fetch.status === CREATED) {
            response = fetch;
        }
    } catch (error) {
        response = error;
    } finally {
        return response;
    }
}