import { Box, FormLabel, Input, Select } from "@chakra-ui/react";
import DrawerComp from "../../../Layouts/Drawer"

const PRFilters = (props) => {
    const {
        filters,
        setFilters,
        searchCF
    } = props;

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return (
        <DrawerComp title={'Filtrar por'} searchByFilters={searchCF}>
            {/* Estados */}
            <Box className='mb-3'>
                <FormLabel>Estado</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='status' value={filters?.status}>
                    <option value={-1}>Todos</option>
                    <option value=''>Creado</option>
                    <option value=''>Levantado</option>
                    <option value=''>Atendiendo</option>
                    <option value=''>Cerrado</option>
                    <option value=''>Cerrado con retraso</option>
                    <option value=''>No hecho</option>
                    <option value=''>Completado</option>
                </Select>
            </Box>
            {/* Segmento */}
            <Box className='mb-3'>
                <FormLabel>Segmento</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='status' value={filters?.status}>
                    <option value={-1}>Todos</option>
                </Select>
            </Box>
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>Fecha inicio</FormLabel>
                <Input type='date' name='start_date' value={filters?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>Fecha fin</FormLabel>
                <Input type='date' name='end_date' value={filters?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

export default PRFilters