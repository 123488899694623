import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import TableRents from "./fragments/Table";

const AdminContracts = () => {
    const store = useStore();
    const { fibra } = store.getState().login;
    const [t, i18n] = useTranslation('global');
    const prefix = 'contracts.data';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/contracts' });
    }, []);

    return (
        <TableRents
            params={{ fibra_id: fibra.id }}
            theaders={[
                '#',
                t(`${prefix}.contract`),
                t(`${prefix}.local`),
                t(`${prefix}.start_date`),
                t(`${prefix}.end_date`),
                t(`${prefix}.fixed_rent`),
                t(`${prefix}.actions`)
            ]}
            keys={['id', 'user', 'name', 'local_mall', 'start_date', 'end_date', 'renta_fija']}
            currency={[{ index: 6 }]}
        />
    );
}

export default AdminContracts