import { Select } from '@chakra-ui/react';
import { Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import FormIcon from '../../Layouts/Forms/FormIcon'

const tax_system = [
    { code: '601', name: 'General de Ley Personas Morales' },
    { code: '603', name: 'Personas Morales con Fines no Lucrativos' },
    { code: '605', name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { code: '606', name: 'Arrendamiento' },
    { code: '608', name: 'Demás ingresos' },
    { code: '609', name: 'Consolidación' },
    { code: '610', name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { code: '611', name: 'Ingresos por Dividendos (socios y accionistas)' },
    { code: '612', name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { code: '614', name: 'Ingresos por intereses' },
    { code: '616', name: 'Sin obligaciones fiscales' },
    { code: '620', name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { code: '621', name: 'Incorporación Fiscal' },
    { code: '622', name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { code: '623', name: 'Opcional para Grupos de Sociedades' },
    { code: '624', name: 'Coordinados' },
    { code: '628', name: 'Hidrocarburos' },
    { code: '607', name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { code: '629', name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { code: '630', name: 'Enajenación de acciones en bolsa de valores' },
    { code: '615', name: 'Régimen de los ingresos por obtención de premios' },
    { code: '625', name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { code: '626', name: 'Régimen Simplificado de Confianza' },
];
const InvoiceCustomer = (props) => {
    const { customer, handleChange } = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Razón social' name='legal_name' value={customer?.legal_name} handleChange={handleChange} />
                <FormIcon title='RFC' name='tax_id' value={customer?.tax_id} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Correo electrónico' name='email' value={customer?.email} handleChange={handleChange} />
                <FormIcon title='Número telefónico [Opcional]' name='phone' value={customer?.phone} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Régimen Fiscal</Form.Label>
                    <Select name='tax_system' onChange={handleChange}>
                        {
                            tax_system.map((item, index) =>
                                <option key={index} value={item.code}>{item.code} - {item.name}</option>
                            )
                        }
                    </Select>
                </Col>
            </Row>
        </Fragment>
    )
}

export default InvoiceCustomer