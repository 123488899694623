import { gql } from "@apollo/client";

// Subscripción para determinar cuando se agrega una nueva sala
export const NEW_ROOM = gql`
  subscription ($user_id: Int = 10) {
    chat_room_users(
		order_by: {id: desc}, 
		limit: 1, 
		where: {user_id: {_eq: $user_id}}
		) {
    	id
  	}
  }
`;