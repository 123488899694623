import Fetcher from "../../libs/Petition"

const url = '/business_names';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Obtiene los datos correspondientes a la fibra seleccionada
 * @returns Object {"data" => BusinessName::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexBusinessName = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
    } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        console.log("Business name [index]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_name_id Identificador de la razón social sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showBusinessName = async ({ headers, business_name_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${business_name_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeBusinessName = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("Store business name: ", fetch);
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param business_name_id Identificador de la razón social que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateBusinessName = async ({ headers, data, business_name_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${business_name_id}`,
            method: 'PUT',
            data,
            headers
        });
        console.log("Update business name: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_name_id Identificador de la razón social que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyBusinessName = async ({ headers, business_name_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${business_name_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}