import _global_es from '../lang/es/global.json';
import _global_en from '../lang/en/global.json';
import _bulk_download_en from '../lang/en/bulk-download.json';
import _bulk_download_es from '../lang/es/bulk-download.json';
import _erp_en from '../lang/en/erp.json';
import _erp_es from '../lang/es/erp.json';
import _maintenance_s_es from '../lang/es/maintenance-standard.json';
import _maintenance_s_en from '../lang/en/maintenance-standard.json';

export const bulk_download_en = _bulk_download_en;
export const bulk_download_es = _bulk_download_es;
export const global_en = _global_en;
export const global_es = _global_es;
export const erp_en = _erp_en;
export const erp_es = _erp_es;
export const maintenance_s_en = _maintenance_s_en;
export const maintenance_s_es = _maintenance_s_es;