import Fetcher from "../../libs/Petition"

const url = '/business_name/queries';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_name_id Obtiene los datos correspondientes a la consulta seleccionada
 * @returns Object {"data" => BusinessNameQuery::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexBusinessNameQuery = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize
    } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        console.log('INDEX business name queries: ', fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param query_id Identificador de la consulta sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showBusinessNameQuery = async ({ headers, query_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${query_id}`,
            method: 'GET',
            headers
        });
        console.log("Business name query [show]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeBusinessNameQuery = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        console.log("Store releases: ", fetch);
        if (fetch.status === CREATED)
            response = fetch.data.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param query_id Identificador de la consulta que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateBusinessNameQuery = async ({ headers, data, query_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${query_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param query_id Identificador de la consulta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyBusinessNameQuery = async ({ headers, query_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${query_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param query_id Identificador de la consulta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const verifyBusinessNameQuery = async ({ headers, query_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `/business_name/query/verify/${query_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}