import { Heading } from "@chakra-ui/layout";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import TableComp from "../Layouts/Table";

const TendersProvider = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/tenders/providers' });
    }, []);

    return (
        <Container fluid>
            <Heading>Licitaciones</Heading>
            <TableComp
                headers={['#', 'Asunto', 'Tipo', 'Fecha', 'Acciones']}
            />
        </Container>
    );
}

export default TendersProvider;