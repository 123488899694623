import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import ComplaintSuggestionsBox from "./fragments/Table";

const ComplaintsSuggestionsProvider = () => {
    const store = useStore();
    const { user } = store.getState().login;
    const [t, i18n] = useTranslation('global');
    const t_prefix = 'complaint-suggestions.table.headers';// Referencia al objeto headers de global.json
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/complaints-suggestions/providers' });
    }, []);

    return (
        <ComplaintSuggestionsBox
            params={{ user_id: user.id }}
            theaders={[
                '#',
                t(`${t_prefix}.title`),
                t(`${t_prefix}.type`),
                t(`${t_prefix}.status`),
                t(`${t_prefix}.created-at`),
                t(`${t_prefix}.updated-at`),
                t(`${t_prefix}.actions`)
            ]}
            tkeys={['id', 'title', 'type', 'status', 'created_at', 'updated_at']}
            tbadges={[{ index: 2, color: 'type_color' }, { index: 3, color: 'status_color' }]}
        />
    );
}

export default ComplaintsSuggestionsProvider;