import { faEnvelopeOpen, faEye, faFileInvoiceDollar, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { indexPedPendProv } from '../../../api/ERP/SAP/PedPendProv';
import Filter from '../../Layouts/Filters';
import FlexButton from '../../Layouts/FlexButton';
import SearchComp from '../../Layouts/Search';
import TableComp from '../../Layouts/Table'
import AuthAdmFacProv from '../AuthAdmFacProv/Index';
import AuthCxpFacProv from '../AuthCxpFacProv/Index';
import RecFacPedProv from '../RecFacPedProv/Index';
import Details from './Details';

const PedPendProv = (props) => {
    const { headers } = props;
    const [pedPendProv, setPedPendProv] = useState([]);
    const [ILifnr, setILifnr] = useState('0300003369');
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState();                   // Modal para ver detalles
    const [modalAuthCxp, setModalAuthCxp] = useState();     // Autorización de facturas por cuentas por pagar              
    const [modalAuthAdm, setModalAuthAdm] = useState();     // Autorización de facturas por administrador
    const [modalRec, setModalRec] = useState();
    const [itemSel, setItemSel] = useState({});
    const [t, i18n] = useTranslation('erp');
    const p = ['PedPendProv.headers'];

    useEffect(() => {
        getPedPendProv();
    }, []);

    const getPedPendProv = async () => {
        setLoading(true);
        let response = await indexPedPendProv({ headers, ILifnr });
        setPedPendProv(response.data);
        setLoading(false);
    }
    const openOrClose = () => setModal(!modal);
    const openOrCloseAuthCxp = () => setModalAuthCxp(!modalAuthCxp);
    const openOrCloseAuthAdm = () => setModalAuthAdm(!modalAuthAdm);
    const openOrCloseRec = () => setModalRec(!modalRec);

    const viewDetails = (item) => {
        setItemSel(item);
        openOrClose();
    }

    const searchPedPendProv = () => getPedPendProv();

    return (
        <Fragment>
            <Row justify='end' className='mb-2'>
                <Filter items={[
                    <SearchComp
                        tooltip={t(`${p[0]}.tt-1`)}
                        onClick={searchPedPendProv}
                        placeholder={t(`${p[0]}.ph-1`)}
                        setString={setILifnr}
                        string={ILifnr}
                    />
                ]}
                />
            </Row>
            <TableComp
                headers={[
                    '#',
                    t(`${p[0]}.CentroDesc`), 
                    t(`${p[0]}.Ebeln`), 
                    t(`${p[0]}.Txz01`), 
                    t(`${p[0]}.Waers`), 
                    t(`${p[0]}.CanXEntregar`), 
                    t(`${p[0]}.ImpXEntregar`), 
                    t(`${p[0]}.ImpTotalPed`),
                    t(`${p[0]}.actions`),
                ]}
                keys={['#', 'CentroDesc', 'Ebeln', 'Txz01', 'Waers', 'CanXEntregar', 'ImpXEntregar', 'ImpTotalPed']}
                body={pedPendProv}
                loading={loading}
                actions={[
                    { icon: faEye, variant: 'outline-primary', tooltip: t(`${p[0]}.ttt-2`), handleClick: viewDetails }
                ]}
                currency={[{ index: 6 }, { index: 7 }]}
            />
            {/* Modal para ver los detalles */}
            {
                modal && <Details
                    onClose={openOrClose}
                    item={itemSel}
                />
            }
            {/* Autorización de Facturas de proveedor por administrador */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrCloseAuthAdm} icon={faFileInvoiceDollar} />
            {
                modalAuthAdm && <AuthAdmFacProv
                    onClose={openOrCloseAuthAdm}
                    headers={headers}
                />
            }

            {/* Autorización de Facturas de proveedor por cuentas por pagar */}
            <FlexButton style={{ bottom: 80, right: 20 }} onClick={openOrCloseAuthCxp} icon={faEnvelopeOpen} />
            {
                modalAuthCxp && <AuthCxpFacProv
                    onClose={openOrCloseAuthCxp}
                    headers={headers}
                />
            }

            {/* Recepción de facturas de proveedores*/}
            <FlexButton style={{ bottom: 140, right: 20 }} onClick={openOrCloseRec} icon={faInbox} />
            {
                modalRec && <RecFacPedProv
                    onClose={openOrCloseRec}
                    headers={headers}
                />
            }
        </Fragment>
    )
}

export default PedPendProv