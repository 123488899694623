import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import ModalPurchaseOrder from "./Modal";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { useStore } from "react-redux";
import { useTranslation } from "react-i18next";

const PurchaseOrderTable = (props) => {
    const { type, title, getPurchaseOrders, page, pageSize, changePage, purchase_orders, loading, totalItems, providers, tactions } = props;
    const store = useStore();
    const { token, fibra, user } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };

    const [modalView, setModalView] = useState(false);
    const [purchase_order, setPurchaseOrder] = useState({});
    const [t, i18n] = useTranslation('global');
    const prefix = 'purchase-order.data';

    useEffect(() => {
        getPurchaseOrders();
    }, [page, pageSize]);



    const openOrCloseModal = () => {
        setModalView(!modalView);
    }

    const view = (item) => {
        setPurchaseOrder(item);
        openOrCloseModal();
    }

    return (
        <Fragment>
            {/* Tabla de órdenes de compra */}
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.folio`),
                    t(`${prefix}.provider`),
                    t(`${prefix}.description`),
                    t(`${prefix}.amount`),
                    t(`${prefix}.actions`)
                ]}
                keys={['id', 'folio', 'user', 'description', 'amount']}
                body={purchase_orders}
                loading={loading}
                loadingItems={10}
                currency={[{ index: 4 }]}
                actions={[
                    {
                        icon: type === 'admin' ? faEdit : faEye,
                        handleClick: view,
                        tooltip: `${t('purchase-order.tooltips.view')} ${type === 'admin' ? t('purchase-order.tooltips.and-edit') : ''} ${t('purchase-order.tooltips.purchase-order')}`,
                        variant: type === 'admin' ? 'outline-success' : 'outline-primary'
                    }, ...tactions
                ]}
            />
            {/* Paginación de órdenes de compra */}
            <PaginationComp totalItems={totalItems} onChange={changePage} current={page} />

            {/* Modal de vista */}
            {
                modalView ?
                    <ModalPurchaseOrder
                        title={title}
                        type={type}
                        headers={headers}
                        fibra_id={fibra.id}
                        user_id={user.id}
                        purchase_order_id={purchase_order.id}
                        po={purchase_order}
                        onClose={openOrCloseModal}
                        update={getPurchaseOrders}
                        providers={providers}
                    /> : null
            }
        </Fragment>
    );
}

export default PurchaseOrderTable;