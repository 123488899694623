import { Fragment } from 'react'
import { Table } from 'react-bootstrap';
import TableComp from '../../Layouts/Table';

const ProductsTab = (props) => {
    const { items } = props;
    const theaders = ['#', 'Clave de producto', 'Descripción', 'Cantidad', 'Precio', 'Unidad de medida'];

    const getCurrency = (cell) => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);
        let format_cell = cell;
        try {
            format_cell = numberFormat.format(cell);
            if (format_cell === '$NaN') format_cell = cell;
        } catch (error) {
            console.log("format cell error", error);
        }
        return format_cell;
    }

    return (
        <Fragment>
            {/* Implementar acceder a objetos para mostrar información en las celdas */}
            {/* <TableComp
                headers={[...theaders, '']}
                keys={['#', 'product_key', 'description', 'price', 'unit_name']}
                body={items}
            /> */}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {theaders.map((item, index) =>
                            <th key={index}>{item}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.product.product_key}</td>
                                <td>{item.product.description}</td>
                                <td>{item.quantity}</td>
                                <td>{getCurrency(item.product.price)}</td>
                                <td>{item.product.unit_name}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </Fragment>
    )
}

export default ProductsTab