import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';

const AddProducts = (props) => {
    const { items, setItems, removeItem } = props;
    const [newItem, setNewItem] = useState({ unit_name: 'Elemento' });

    const theaders = ['#', 'Clave de producto', 'Descripción', 'Cantidad', 'Precio', 'Unidad de medida', 'Acciones'];

    const getCurrency = (cell) => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);
        let format_cell = cell;
        try {
            format_cell = numberFormat.format(cell);
            if (format_cell === '$NaN') format_cell = cell;
        } catch (error) {
            console.log("format cell error", error);
        }
        return format_cell;
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setNewItem({ ...newItem, [name]: value });
    }
    const validateForm = () => {
        let response = true;
        if (newItem.product_key === '' || newItem.product_key === undefined) response = false;
        if (newItem.description === '' || newItem.description === undefined) response = false;
        if (newItem.quantity === '' || newItem.quantity === undefined) response = false;
        if (newItem.price === '' || newItem.price === undefined) response = false;
        return response;
    }
    const addNewItem = () => {
        if (validateForm()) {
            let newItemO = {
                quantity: newItem.quantity,
                product: {
                    description: newItem.description,
                    product_key: newItem.product_key,
                    price: newItem.price
                }
            }
            setItems([...items, newItemO]);
            setNewItem({
                description: '',
                product_key: '',
                quantity: 1,
                unit_name: 'Elemento',
                price: 0
            });
        }
    }
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Clave del producto' name='product_key' value={newItem?.product_key} handleChange={handleChange} />
                <FormIcon title='Descripción' name='description' value={newItem?.description} handleChange={handleChange} />
                <FormIcon title='Cantidad' name='quantity' value={newItem?.quantity} handleChange={handleChange} type='number' />
                <FormIcon title='Precio' name='price' value={newItem?.price} handleChange={handleChange} type='number' />
                <Col className='d-flex justify-content-center align-items-center'>
                    <ButtonIcon name='Agregar' icon={faPlus} onClick={addNewItem} />
                </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {theaders.map((item, index) =>
                            <th key={index}>{item}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.product.product_key}</td>
                                <td>{item.product.description}</td>
                                <td>{item.quantity}</td>
                                <td>{getCurrency(item.product.price)}</td>
                                <td>{item.product.unit_name}</td>
                                <td>
                                    <ButtonIcon icon={faTrashAlt} variant='outline-danger' tooltip='Remover producto' onClick={() => removeItem(index)} />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </Fragment>
    )
}

export default AddProducts