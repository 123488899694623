import FetcherFa from "../../libs/FetcherFa"

const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_USER_SECRET_KEY}`,
}

export const indexOrganizations = async () => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await FetcherFa({
            method: 'GET',
            url: '/organizations',
            headers
        });
        console.log("Fetch organizations: ", fetch);
        response = {
            data: fetch.data.data,
            totalItems: fetch.data.total_results
        }
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response
    }
}