import { useToast } from '@chakra-ui/react';
import { faCloudDownloadAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { indexBusinessNameQuery, showBusinessNameQuery, verifyBusinessNameQuery } from '../../../api/Cfdi/BusinessNameQueries';
import downloadFile from '../../../libs/DownloadFile';
import FlexButton from '../../Layouts/FlexButton';
import PaginationComp from '../../Layouts/Pagination';
import TableComp from '../../Layouts/Table'
import NewRequest from './NewRequest';

const TableRequests = (props) => {
    const {
        headers,
        fibra_id,
        socket
    } = props;

    const toast = useToast();
    const [loadingPackages, setLoadingPackages] = useState(false);
    const [modal, setModal] = useState(false);
    const [requests, setRequest] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('bulk_download');

    useEffect(() => {
        getBusinessNameRequests();
    }, [page, pageSize, socket]);

    const getBusinessNameRequests = async () => {
        setLoading(true);
        let response = await indexBusinessNameQuery({ headers, fibra_id, page, pageSize });
        setRequest(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const verifyStatus = async (query) => {
        let response = await verifyBusinessNameQuery({ headers, query_id: query.id });
        if (response) getBusinessNameRequests();
    }
    const openOrClose = () => setModal(!modal);

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const downloadPackages = async (item) => {
        setLoadingPackages(true);
        downloadFile({ headers, toast, url: `/business_name/query/download/${item.id}`, type: 'application/json', name: item.id });
        setLoadingPackages(false);
    }
    return (
        <Fragment>
            {/* Tabla de las peticiones */}
            <TableComp
                headers={[
                    '#',
                    t('requests-table.request-type'),
                    t('requests-table.download-type'),
                    t('requests-table.tax-id'),
                    t('requests-table.request-date'),
                    t('requests-table.status'),
                    t('requests-table.created-at'),
                    t('requests-table.actions'),
                ]}
                keys={['#', 'request_type', 'download_type', 'rfc', 'query_date', 'status', 'created_at']}
                body={requests}
                loading={loading}
                actions={[
                    {
                        icon: faRedo, tooltip: t('requests-table.btn-tt-verify'), handleClick: verifyStatus,
                        dinamics: [{ key: 'status', values: ['processing'] }]
                    },
                    {
                        icon: faCloudDownloadAlt, tooltip: t('requests-table.btn-tt-download'), variant: 'outline-success', handleClick: downloadPackages,
                        dinamics: [{ key: 'status', values: ['complete', 'incomplete'] }]
                    }
                ]}
                badges={[{ index: 5, color: 'status_color' }]}
                loadingItems={pageSize}
                page={page}
            />
            {/* Paginación */}
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
            />
            {/* Agregar petición */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrClose} />
            {
                modal && <NewRequest
                    onClose={openOrClose}
                    headers={headers}
                    fibra_id={fibra_id}
                    update={getBusinessNameRequests}
                />
            }
        </Fragment>
    )
}

export default TableRequests