import Fetcher from "../../../libs/Petition";

const url = 'auth/cxp/fac/prov';
const CREATED = 201;
const STATUS_OK = 200;

export const updateAuthCxpFacProv = async ({ headers, data }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            headers,
            data
        });
        console.log("Auth Cxp Fac Prov: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}