import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useStore } from "react-redux";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import ModalView from "./Modal";
import { Row } from 'antd';
import { useTranslation } from "react-i18next";
import MailboxFilters from "./MailboxFilters";

const Mailbox = (props) => {
    const {
        is_admin,
        mailbox,
        loading,
        invoice,
        setInvoice,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalItems,
        tactions,
        searchInvoice,
        filters, 
        setFilters,
    } = props;

    const store = useStore();
    const cfdi_statuses = useSelector(state => state.cfdi.state);
    const { token } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    // Hooks locales
    const [modal, setModal] = useState(false);              // Modal incativo por default
        const [t, i18n] = useTranslation('global');
        const prefix = 'mailbox.data';

    const openOrClose = () => setModal(!modal);

    const viewInvoice = (item) => {
        setInvoice(item);
        openOrClose();
    }
    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    return (
        <Fragment>
            {/* Filtro: Para realizar consultas específicas */}
            <Row justify='end' className='mb-2'>
                <MailboxFilters
                    cfdi_statuses={cfdi_statuses}
                    filters={filters}
                    setFilters={setFilters}
                    searchInvoice={searchInvoice}
                />
            </Row>
            {/* Table: Lista de facturas consultadas */}
            {
                tactions && <TableComp
                    headers={[
                        t(`${prefix}.folio`),
                        t(`${prefix}.uuid`),
                        t(`${prefix}.receptor`),
                        t(`${prefix}.transmitter`),
                        t(`${prefix}.payment_method`),
                        t(`${prefix}.currency`),
                        t(`${prefix}.total`),
                        t(`${prefix}.emission_date`),
                        t(`${prefix}.status`),
                        t(`${prefix}.actions`)
                    ]}
                    keys={['folio', 'uuid', 'nombre_receptor', 'nombre_emisor', 'payment_method', 'currency', 'total', 'emission_date', 'cfdi_status']}
                    body={mailbox}
                    actions={[
                        { handleClick: viewInvoice, tooltip: t('mailbox.table.tooltips.details') },
                        ...tactions
                    ]}
                    loading={loading}
                    loadingItems={pageSize}
                    badges={[{ index: 8, color: 'color' }]}
                    currency={[{ index: 6 }]}
                />
            }
            {/* Paginación */}
            <PaginationComp totalItems={totalItems} onChange={changePage} current={page} />
            {/* Modal: Detalles de factura */}
            {
                modal && <ModalView
                    onClose={openOrClose}
                    invoice={invoice}
                    headers={headers}
                    is_admin={is_admin}
                />
            }
        </Fragment>
    );
}

export default Mailbox;