import { useToast } from '@chakra-ui/react';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react'
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { storeBusinessName } from '../../../api/Cfdi/BusinessNames';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'
import { data_struct } from '../validate/BusinessName';

const NewBusinessName = (props) => {
    const { onClose, headers, update, fibra_id } = props;
    const [form, setForm] = useState({ fibra_id });
    const toast = useToast();
    const [t, i18n] = useTranslation('bulk_download');
    const p = 'modal-business-name';
    const pt = `${p}.toasts`;
    
    // Método para manejar los cambios del formulario
    const handleChange = (e) => {
        let { value, name } = e.target;
        setForm({ ...form, [name]: value });
    }

    const handleChangeFile = (e) => {
        setForm({ ...form, [e.target.name]: e.target.files[0] });
    }
    // Validar formulario
    const validateForm = () => {
        let response = true;
        for (let index = 0; index < data_struct.length; index++) {
            const { key, name } = data_struct[index];
            if (!form.hasOwnProperty(key)) {
                response = false;
                setToast({ toast, title: t(`${pt}.${name}.title`), description: t(`${pt}.${name}.description`), status: 'warning' });
                break;
            }
        }
        return response;
    }
    // Agregar razón social
    const addBusinessName = async () => {
        let data = new FormData();
        if (validateForm()) { // Si el formulario es válido
            data.append('name', form.name);
            data.append('rfc', form.rfc);
            data.append('cer', form.cer);
            data.append('key', form.key);
            data.append('pass_phrase', form.pass_phrase);
            data.append('fibra_id', form.fibra_id);
            let response = await storeBusinessName({ headers, data });
            if (response) {
                setToast({ toast, title: t(`${pt}.success.title`), description: t(`${pt}.success.description`), status: 'success' });
                update();
                onClose();
            }else{
                setToast({ toast, title: t(`${pt}.failure.title`), description: t(`${pt}.failure.description`), status: 'error' });
            }
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title={t(`${p}.title`)}
            size='md'
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.business-name`)} type='text' placeholder={t(`${p}.business-name-ph`)} name='name' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.tax-id`)} type='text' placeholder={t(`${p}.tax-id-ph`)} name='rfc' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.cer-file`)} type='file' accept='.cer' name='cer' handleChange={handleChangeFile} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.key-file`)} type='file' accept='.key' name='key' handleChange={handleChangeFile} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.pass-phrase`)} type='password' name='pass_phrase' handleChange={handleChange} />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${p}.btn-close`)} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faSave} name={t(`${p}.btn-save`)} variant='outline-success' onClick={addBusinessName} />
            </Fragment>}
        />
    )
}

export default NewBusinessName