import { useToast } from "@chakra-ui/toast";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import TabsComp from "../Layouts/Tabs";
import Docs from "./fragments/Users/Docs";
import History from "./fragments/Users/History";
import Info from "./fragments/Users/Info";

const RepseProvider = () => {
    const store = useStore();
    const toast = useToast();
    const { token, fibra, user } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t, i18n] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/repse-provider' });
    }, []);

    return (
        <Fragment>
            <TabsComp
                tablist={[
                    t('repse.tabs.info'),
                    t('repse.tabs.docs'),
                    t('repse.tabs.movements'),
                ]}
                tabpanels={[
                    <Info headers={headers} user={user} />,
                    <Docs headers={headers} user={user} fibra={fibra} toast={toast} />,
                    <History user={user} headers={headers} fibra_id={fibra.id}/>
                ]}
            />
        </Fragment>
    );
}

export default RepseProvider;