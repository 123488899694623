import { gql } from "@apollo/client";

// Escucha un mensaje entrante, sólo escucha sus propios mensajes [deprecated]

export const NEW_MESSAGE = gql`
  subscription NewMessage($user_id: Int) {
    chat_messages(
      order_by: { id: desc }
      limit: 1
      where: { user_id: { _eq: $user_id } }
    ) {
      id
      message
    }
  }
`;

// Escucha los mensajes que llegan en las salas del usuario actual, 
// recibe array de los identificadores de las salas que tiene

export const NEW_MESSAGE_IN_ROOMS = gql`
  subscription NewMessageInRooms($_in: [Int!]) {
    chat_messages(
      where: { chat_room_id: { _in: $_in } }
      limit: 1
      order_by: { id: desc }
    ) {
      id
      message
      user{
        id
        first_name
        last_name
      }
      user_id
      chat_room_id
      created_at
    }
  }
`;
