import Fetcher from "../libs/Petition"

const url = '/purchase_orders';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page Se utiliza para conocer la página en la que se encuentra
 * @param pageSize Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener los registros de un proveedor
 * @returns Object {"data" => PurchaseOrder::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexPurchaseOrders = async ({ headers, fibra_id, page, pageSize, user_id, string }) => {
    let response = [];
    try {
        let params = { fibra_id, page, pageSize, string };
        if (user_id) params.user_id = user_id;
        if (string) params.string = string;
        
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = [];
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param purchase_order_id Identificador de la orden de compra sobre el que se hará la consulta
 * @returns PurchaseOrder Retorna un objeto tipo PurchaseOrder
 */
export const showPurchaseOrders = async ({ headers, purchase_order_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'GET',
            headers
        });
        // console.log("Purchase order [show fetch]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storePurchaseOrder = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param purchase_order_id Identificador de la orden de compra que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updatePurchaseOrder = async ({ headers, data, purchase_order_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'PUT',
            data,
            headers
        });
        console.log("fetch: ", fetch);
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param purchase_order_id Identificador de la orden de compra que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyPurchaseOrder = async ({ headers, purchase_order_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}