// React imports
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Providers
import { Provider } from 'react-redux';
import store from './redux/store';
import { CookiesProvider } from 'react-cookie';
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
// import { createClient } from 'graphql-ws';
import { WebSocketLink } from "@apollo/client/link/ws";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// Styles
import 'bootstrap/dist/css/bootstrap.css';

// Importar archivos de idioma
import {
	global_es,
	global_en,
	bulk_download_en,
	bulk_download_es,
	erp_en,
	erp_es,
	maintenance_s_es,
	maintenance_s_en
} from './lang/index';

const {
	REACT_APP_PROTOCOL_HTTP: P_HTTP,
	REACT_APP_PROTOCOL_WS: P_WS,
	REACT_APP_URI: URI,
	REACT_APP_HASURA_SECRET: TOKEN_HASURA
} = process.env;

const lang = localStorage.getItem('lang');
// console.log("Local storage lang: ", lang);

// Inicializar i18next para las traducciones
i18next.init({
	interpolation: { escapeValue: false },
	lng: lang || "es",
	resources: {
		es: {
			global: global_es,
			bulk_download: bulk_download_es,
			erp: erp_es,
			maintenance: maintenance_s_es
		},
		en: {
			global: global_en,
			bulk_download: bulk_download_en,
			erp: erp_en,
			maintenance: maintenance_s_en
		}
	}
});

// Add an authorization header to every HTTP request by chaining together Apollo Links

const httpLink = createHttpLink({
	uri: P_HTTP + URI
});

const wsLink = (token) => {
	return new WebSocketLink({
		uri: P_WS + URI,
		options: {
			reconnect: true,
			connectionParams: () => {
				return { headers: { "x-hasura-admin-secret": token } };
			},

		},
		// webSocketImpl
	});
};

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = TOKEN_HASURA;
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			"x-hasura-admin-secret": token
		}
	}
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink(TOKEN_HASURA),
	authLink.concat(httpLink),
);

// Definicón de cliente APOLLO
const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: splitLink,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ApolloProvider client={client}>
				<CookiesProvider>
					<ChakraProvider>
						<I18nextProvider i18n={i18next}>
							<App />
						</I18nextProvider>
					</ChakraProvider>
				</CookiesProvider>
			</ApolloProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();