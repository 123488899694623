import { useEffect } from 'react';
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import TabsComp from '../Layouts/Tabs'
import CompPagoPendProv from './CompPagoPendProv/Index';
import PedPendProv from './PedPendProv/Index'

const SAPComponent = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const [t, i18n] = useTranslation('erp');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/erp/sap' });
    }, []);

    return (
        <Fragment>
            <TabsComp
                tablist={[
                    t('tabs.pedpendprov'),
                    t('tabs.comppagopend'),
                    // 'AutFacProv Administrador',
                    // 'AutCompPago Cuentas x Pagar',
                    // 'AutFacProv Cuentas x Pagar',
                    // 'RecCompPago Proveedores',
                    // 'RecFac Proveedores'
                ]}
                tabpanels={[
                    <PedPendProv headers={headers} />,
                    <CompPagoPendProv headers={headers} />
                ]}
            />
        </Fragment>
    )
}

export default SAPComponent