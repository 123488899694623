import { Fragment, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { indexRentRequest } from "../../api/Malls/RentRequest";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Main from "./fragments/Main";

const Client = () => {
    const store = useStore();
    const { token, fibra, user } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [rent_requests, setRentRequests] = useState([]);
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [status, setStatus] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mall/local/client' });
    }, []);

    useEffect(() => {
        getRentRequest();
    }, [page, pageSize, status]);

    const getRentRequest = async () => {
        setLoading(true);
        let response = await indexRentRequest({ headers, fibra_id: fibra.id, page, pageSize, user_id: user.id, status });
        // console.log("Rent request index: ", response);
        setTotalItems(response.totalItems);
        setRentRequests(response.data);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const changeStatus = (value) => {
        setStatus(value);
    }
    return (
        <Fragment>
            <Main
                type='client'
                rent_requests={rent_requests}
                totalItems={totalItems}
                page={page}
                pageSize={pageSize}
                loading={loading}
                changePage={changePage}
                changeStatus={changeStatus}
                headers={headers}
                fibra_id={fibra.id}
                user_id={user.id}
                update={getRentRequest}
            />
        </Fragment>
    );
}

export default Client