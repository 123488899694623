import { Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { indexChatRooms } from '../../../api/Chat/Room'
import Chat from './Chat'
import Rooms from './Rooms'
import Send from './Send'

import audioNot from '../../../audio/iphone-notificacion.mp3';
import audioNew from '../../../audio/new_message.mp3';
import audioSend from '../../../audio/send_message_wa.mp3';

import { useSubscription } from '@apollo/client'
import { NEW_ROOM } from '../../../graphql/subscriptions/RoomUsersSubscription'
import { NEW_MESSAGE_IN_ROOMS } from '../../../graphql/subscriptions/ChatSubscription'

const _ = require('underscore');

const Main = (props) => {
    const { updateRooms } = props;
    const dispatch = useDispatch();
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const user_id = login.user.id;
    const room_id = useSelector(state => state.chat.room_id);

    const [chatRoomSel, setChatRoomSel] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [idRooms, setIdRooms] = useState([]);
    const [messages, setMessages] = useState([]);
    const [countMessages, setCountMessages] = useState([]);

    // Subscription para nuevas salas
    const {
        data: new_room_data
    } = useSubscription(NEW_ROOM, { variables: { user_id } });

    // Subscription para mensajes entrantes
    const {
        data: new_message_data
    } = useSubscription(NEW_MESSAGE_IN_ROOMS, { variables: { _in: idRooms } });

    // Efecto que obtiene y actualiza las salas del usuario
    useEffect(() => {
        getRooms();
    }, [updateRooms, new_room_data]);

    useEffect(() => {
        let msg = new_message_data?.chat_messages[0];
        if (msg) setNewMessage(msg)
    }, [new_message_data]);

    const setNewMessage = (msg) => {
        if (room_id === msg?.chat_room_id) {
            if (user_id !== msg?.user_id) new Audio(audioNew).play();
            else new Audio(audioSend).play();
            setMessages(current => [...current, msg]);
        } else {
            new Audio(audioNot).play();
            setCountMessages(
                countMessages.map((item) => {
                    if (msg.chat_room_id === item.id) item.count++;
                    return item;
                })
            );
        }
    }

    // Obtener todoas las salas a las que pertenece el usuario
    const getRooms = async () => {
        let response = await indexChatRooms({ headers, user_id });
        if (response.data.length > 0) {
            setIdRooms(response.data.map((room) => room.id));
            setCountMessages(response.data.map((r) => { return { id: r.id, count: 0 } })); // Inicializar contador para notificaciones en 0
        }
        setRooms(response.data);
    }
    // Evento para cambio de sala
    const handleChatRoom = (chat_room_id) => {
        setChatRoomSel(chat_room_id);
        setCountMessages(
            countMessages.map((item) => {
                if (chat_room_id === item.id) item.count = 0;
                return item;
            })
        );
        dispatch({ type: 'SET_CHAT_ROOM_ID', chat_room_id });
    }

    return (
        <Container>
            <Grid
                h='82vh'
                templateRows='repeat(10, 1fr)'      // Cantidad de filas que se manejan
                templateColumns='repeat(8, 1fr)'    // Cantidad de columnas que se manejan
                gap={2}                             // Espacio entre los GridItems
                bg='#e6ebee'
            >
                <GridItem
                    rowSpan={10}
                    colSpan={2}
                    borderColor='#dfe1e5'
                    bg='#fff'
                >
                    {/* Componente para listar las salas disponibles */}
                    <Rooms
                        rooms={rooms}
                        handleChatRoom={handleChatRoom}
                        countMessages={countMessages}
                    />
                </GridItem>
                <GridItem
                    rowSpan={9}
                    colSpan={6}
                    borderColor='#dfe1e5'
                    bg='#e6ebee'
                >
                    {/* Componente para listar los mensajes de la sala seleccionada */}
                    <Chat
                        chat_room_id={chatRoomSel}
                        messages={messages}
                        setMessages={setMessages}
                    />
                </GridItem>
                <GridItem
                    rowSpan={1}
                    colSpan={6}
                    borderColor='#dfe1e5'
                    bg='#fff'
                >
                    {/* Componente para enviar un nuevo mensaje */}
                    <Send
                        chat_room_id={chatRoomSel}
                        headers={headers}
                        user_id={user_id}
                    />
                </GridItem>
            </Grid>
        </Container>
    )
}

export default Main;