import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal'
import TabsComp from '../../Layouts/Tabs';
import AddressTab from './AddressTab';
import ClientTab from './ClientTab';
import InformationTab from './InformationTab';
import ProductsTab from './ProductsTab';
import StampTab from './StampTab';

const InvoiceDetails = (props) => {
    const { onClose, invoice } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Detalles de la factura'
            size='xl'
            body={<Fragment>
                <TabsComp
                    tablist={['Información general', 'Cliente', 'Productos', 'Timbrado', 'Dirección']}
                    tabpanels={[
                        <InformationTab invoice={invoice}/>,
                        <ClientTab customer={invoice?.customer}/>,
                        <ProductsTab items={invoice?.items}/>,
                        <StampTab stamp={invoice?.stamp}/>,
                        <AddressTab address={invoice?.address}/>
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    )
}

export default InvoiceDetails