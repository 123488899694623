import { useToast } from '@chakra-ui/react';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { storeBusinessName } from '../../../api/Cfdi/BusinessNames';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'

const UpdateBusinessName = (props) => {
    const { onClose, headers, business_name_id } = props;
    const [form, setForm] = useState({});
    const [t, i18n] = useTranslation('bulk_download');
    const prefix = 'update-business-name';
    const p_t = `${prefix}.toasts`;
    const toast = useToast();

    const validateFiles = () => {
        let response = true;
        if (!form.cer || form.cer === undefined) {
            setToast({
                toast,
                title: t(`${p_t}.cer.title`),
                description: t(`${p_t}.cer.description`),
                status: 'warning'
            });
            response = false;
        }
        if (!form.key || form.key === undefined) {
            setToast({
                toast,
                title: t(`${p_t}.key.title`),
                description: t(`${p_t}.key.description`),
                status: 'warning'
            });
            response = false;
        }
        return response;
    }
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.files[0] });
    }
    const saveFIEL = async () => {
        if (validateFiles()) {
            let data = new FormData();
            data.append('cer', form.cer);
            data.append('key', form.key);
            data.append('business_name_id', business_name_id);
            let response = await storeBusinessName({ headers, data });
            if (response) {
                setToast({
                    toast,
                    title: t(`${p_t}.success.title`),
                    description: t(`${p_t}.success.description`),
                    status: 'success'
                });
                onClose();
            } else {
                setToast({
                    toast,
                    title: t(`${p_t}.failure.title`),
                    description: t(`${p_t}.failure.description`),
                    status: 'error'
                });
            }
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t(`${prefix}.title`)}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.cer-file`)} type='file' name='cer' handleChange={handleChange} accept='.cer' />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.key-file`)} type='file' name='key' handleChange={handleChange} accept='.key' />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t(`${prefix}.btn-save`)} icon={faSave} variant='outline-success' onClick={saveFIEL} />
            </Fragment>}
        />
    )
}

export default UpdateBusinessName