import { Select } from "@chakra-ui/react";
import { Fragment } from "react"
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const FormRequest = (props) => {
    const { handleChange } = props;
    const [t, i18n] = useTranslation('bulk_download');
    const p = 'modal-request';

    return (
        <Fragment>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>{t(`${p}.download-type`)}</Form.Label>
                    <Select placeholder={t(`${p}.download-type`)} name='download_type' onChange={handleChange}>
                        <option value='all'>{t(`${p}.dt-1`)}</option>
                        <option value='received'>{t(`${p}.dt-2`)}</option>
                        <option value='issued'>{t(`${p}.dt-3`)}</option>
                    </Select>
                </Col>
                <Col>
                    <Form.Label>{t(`${p}.request-type`)}</Form.Label>
                    <Select placeholder={t(`${p}.request-type`)} name='request_type' onChange={handleChange}>
                        <option value='all'>{t(`${p}.rt-1`)}</option>
                        <option value='metadata'>{t(`${p}.rt-2`)}</option>
                        <option value='cfdi'>{t(`${p}.rt-3`)}</option>
                    </Select>
                </Col>
                <FormIcon title={t(`${p}.rfc-filter`)} name='rfc' placeholder={t(`${p}.optional`)} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${p}.start-date`)} name='start_date' type='date' handleChange={handleChange} />
                <FormIcon title={t(`${p}.start-time`)} name='start_time' type='time' handleChange={handleChange} defaultValue='00:00' />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${p}.end-date`)} name='end_date' type='date' handleChange={handleChange} />
                <FormIcon title={t(`${p}.end-time`)} name='end_time' type='time' handleChange={handleChange} defaultValue='23:59' />
            </Row>
        </Fragment>
    )
}

export default FormRequest