import { useToast } from '@chakra-ui/react'
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons'
import { Fragment, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { storeRecCompPagoProv } from '../../../api/ERP/SAP/RecCompPagoProv'
import setToast from '../../../libs/SetToast'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import FormIcon from '../../Layouts/Forms/FormIcon'
import ModalComp from '../../Layouts/Modal'
import TableComp from '../../Layouts/Table'

const RecCompPagoProv = (props) => {
    const { onClose, headers } = props;
    const [data, setData] = useState({});
    const [list, setList] = useState([]);
    const [t, i18n] = useTranslation('erp');
    const p = ['RecCompPagoProv.form', 'RecCompPagoProv.item'];
    const toast = useToast();

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        return response;
    }
    const saveData = async () => {
        if (validate()) {
            try {
                let response = await storeRecCompPagoProv({ headers, data });
                console.log("response: ", Object.values(response.data[0]));
                setToast({ toast, title: 'Respuesta de la petición: ', description: Object.values(response.data[0])[0], status: 'warning' });
            } catch (error) {
                setToast({ toast, title: '¡Error!', description: 'Error al procesar la solicitud', status: 'error' });
            }
        }
    }

    return (
        <ModalComp
            title={t('RecCompPagoProv.title')}
            size='xl'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Bukrs`)} name='Bukrs' value={data?.Bukrs} handleChange={handleChange} />
                    <FormIcon title={t(`${p[0]}.Gjahr`)} name='Gjahr' value={data?.Gjahr} handleChange={handleChange} />
                    <FormIcon title={t(`${p[0]}.Augbl`)} name='Augbl' value={data?.Augbl} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Augdt`)} name='Augdt' value={data?.Augdt} handleChange={handleChange} />
                    <FormIcon title={t(`${p[0]}.Wrbtr`)} name='Wrbtr' value={data?.Wrbtr} icon={'$'} handleChange={handleChange} />
                    <FormIcon title={t(`${p[0]}.Waers`)} name='Waers' value={data?.Waers} handleChange={handleChange} />
                    <FormIcon title={t(`${p[0]}.Zlsch`)} name='Zlsch' value={data?.Zlsch} handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>

                </Row>
                <Row>
                    <Col>
                        <TableComp
                            headers={[
                                '#',
                                t(`${p[1]}.Belnr`),
                                t(`${p[1]}.Blart`),
                                t(`${p[1]}.Gjahr`),
                                t(`${p[1]}.HBldat`),
                                t(`${p[1]}.Serie`),
                                t(`${p[1]}.Folio`),
                                t(`${p[1]}.ImporteFactura`),
                                t(`${p[1]}.Waers`),
                                t(`${p[1]}.MetodoPago`),
                                t(`${p[1]}.Uuid`),
                            ]}
                            keys={['#', 'Belnr', 'Blart', 'Gjahr', 'HBldat', 'Serie', 'Folio', 'ImporteFactura', 'Waers', 'MetodoPago', 'Uuid']}
                            body={list}
                            currency={[{ index: 6 }]}
                        />
                    </Col>
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t('RecCompPagoProv.btn-close')} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t('RecCompPagoProv.btn-save')} icon={faSave} variant='outline-success' onClick={saveData} />
            </Fragment>}
        />
    )
}

export default RecCompPagoProv