import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { indexRelease } from "../../api/Releases";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import FlexButton from "../Layouts/FlexButton";
import AddRelease from "./fragments/AddRelease";
import ReleasesTable from "./fragments/Table";

const ReleasesAdmin = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [t, i18n] = useTranslation('global');
    const prefix = 'releases.data';
    const [releases, setReleases] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const openOrClose = () => setShowModal(!showModal);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/releases' });
        getReleases();
    }, []);

    const getReleases = async () => {
        let response = await indexRelease({ headers, page, pageSize, fibra_id });
        // console.log("GET RELEASES:: ", response);
        setReleases(response.data);
        setTotalItems(response.totalItems);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const addRelease = () => {
        // console.log("Add release");
        openOrClose();
    }
    return (
        <Fragment>
            <ReleasesTable
                theaders={[
                    '#',
                    t(`${prefix}.title`),
                    t(`${prefix}.status`),
                    t(`${prefix}.created_at`),
                    t(`${prefix}.actions`),
                ]}
                tkeys={['id', 'title', 'active', 'created_at']}
                tbadges={[{ index: 2, color: 'color_active' }]}
                releases={releases}
                totalItems={totalItems}
                page={page}
                changePage={changePage}
            />
            {/* FAB para agregar un comunicado */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={addRelease} />
            {/* Modal para agregar comunicado */}
            {
                showModal &&
                <AddRelease
                    onClose={openOrClose}
                    update={getReleases}
                />
            }
        </Fragment>
    );
}

export default ReleasesAdmin;