import Fetcher from "../../../libs/Petition";

const url = '/ped/pend/prov';
const CREATED = 201;
const STATUS_OK = 200;

export const indexPedPendProv = async ({ headers, ILifnr }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { ILifnr };
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            headers,
            params
        });
        console.log("Ped pend prov: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}