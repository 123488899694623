import { Fragment, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { indexRentRequest } from "../../api/Malls/RentRequest";
import History from "./fragments/History";
import { Heading } from "@chakra-ui/react";
import { PageHeader, Select } from 'antd';
import { useTranslation } from "react-i18next";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";

const { Option } = Select;

const Admin = () => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [rent_requests, setRentRequests] = useState([]);
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [status, setStatus] = useState(null);
    const [t, i18n] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mall/local/admin' });
    }, []);

    useEffect(() => {
        getRentRequest();
    }, [page, pageSize, status]);

    const getRentRequest = async () => {
        setLoading(true);
        let response = await indexRentRequest({ headers, fibra_id: fibra.id, page, pageSize, status });
        // console.log("Rent request index: ", response);
        setTotalItems(response.totalItems);
        setRentRequests(response.data);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const changeStatus = (value) => {
        setStatus(value);
    }
    return (
        <Fragment>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('rents.title')}</Heading>}
                extra={[
                    <Select placeholder={t('rents.filters.status')} onChange={changeStatus} name='status' style={{ width: 180 }}>
                        <Option value={null}>{t('rents.filters.all')}</Option>
                        <Option value='pendiente'>{t('rents.filters.pending')}</Option>
                        <Option value='atendiendo'>{t('rents.filters.attending')}</Option>
                        <Option value='cancelado'>{t('rents.filters.cancel')}</Option>
                        <Option value='resuelto'>{t('rents.filters.solve')}</Option>
                    </Select>,
                ]}
            >
            </PageHeader>
            <History
                type='admin'
                rent_requests={rent_requests}
                totalItems={totalItems}
                page={page}
                pageSize={pageSize}
                loading={loading}
                changePage={changePage}
            />
        </Fragment>
    );
}

export default Admin