import { Fragment } from 'react'

const AdminHome = () => {
    const dashboard = 'https://metabase.victum-re.online/public/dashboard/a4540b32-35ea-4e7b-a896-36c3d82c0c77';

    return (
        <Fragment>
            <iframe
                src={dashboard}
                frameborder="0"
                width="100%"
                height="900"
                allowtransparency
            ></iframe>
        </Fragment>
    )
}

export default AdminHome;
