import { Form, FloatingLabel, Row, Col, FormSelect, InputGroup, FormControl } from "react-bootstrap";
import { useState, useEffect, Fragment } from "react";
import { faArrowLeft, faPlus, faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { showPurchaseOrders, storePurchaseOrder, updatePurchaseOrder } from "../../../api/PurchaseOrders";
import { destroyPurchaseOrderItem, storePurchaseOrderItem, updatePurchaseOrderItem } from "../../../api/PurchaseOrderItems";
import { uploadInvoice } from '../../../api/Cfdi/Mailbox';
import ModalComp from "../../Layouts/Modal";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import TableComp from "../../Layouts/Table";
import { Divider } from "antd";
import { Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import setToast from "../../../libs/SetToast";
import _ from 'underscore';
import FormIcon from "../../Layouts/Forms/FormIcon";
import FormInvoice from "../../Mailbox/fragments/FormInvoice";
import SkeletonForm from "../../Layouts/Skeletons/Form";
import { useTranslation } from "react-i18next";

const ModalPurchaseOrder = (props) => {
    const { headers, po, purchase_order_id, fibra_id, onClose, update, title, providers, type, user_id } = props;

    const toast = useToast();
    const [folio, setFolio] = useState('');                         // Folio de la orden de compra
    const [form, setForm] = useState({});                           // Data de la orden de compra
    const [amount, setAmount] = useState(null);
    const [purchase_order, setPurchaseOrder] = useState({});
    const [loading, setLoading] = useState(true);
    // Partidas 
    const [item, setItem] = useState({});                           // Datos de nueva partida para ser agregada
    const [items, setItems] = useState([]);                         // Partidas de la orden de compra seleccionada
    const [items_destroy, setItemsDestroy] = useState([]);          // Array de items para ser eliminados
    const [items_sel, setItemsSel] = useState([]);                  // Array de items para facturar
    // Tipo de acciones  para la tabla de partidas
    const [actions, setActions] = useState();                       // [] de buttons o {} de switch 
    const [actionType, setActionType] = useState('');               // Switch para controles de tipo switch, '' para botones
    // Carga de archivos para factura
    const [files, setFiles] = useState({});
    const [invoice, setInvoice] = useState({});
    const [successfull, setSuccessfull] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [t, i18n] = useTranslation('global');
    const prefix = 'purchase-order.toasts';
    const p_data = 'purchase-order.data';

    useEffect(() => {
        if (purchase_order_id) { getPurchaseOrder(); } else { getFolio(); setLoading(false); }
    }, []);

    // Actualiza el total de la orden de compra cada vez que las partidas se actualizan
    useEffect(() => {
        // console.log("items: ", items);
        updateTotal();
    }, [items, items_destroy]);
    // Carga de archivos
    useEffect(() => {
        // console.log("Files: ", files);
    }, [files])

    const getPurchaseOrder = async () => {  // Obtiene la orden de compra que corresponde al purchase_order_id del props 
        let response = await showPurchaseOrders({ headers, purchase_order_id });
        let new_items = [];
        // Se agrega el atributo index a cada objeto, comenzando por 1
        response.items.map((_item, _index) => { new_items.push({ ..._item, index: _index + 1 }); });
        setItems(new_items);                // Se actualizan las partidas de la orden de compra
        setFolio(response.folio);           // Se obtiene el folio de la orden de compra
        setPurchaseOrder(response);         // Se guarda todo el objeto de la orden de compra
        // console.log("Purchase order: ", response);
        setLoading(false);                  // Se indica que la carga de datos ha concluido
    }

    const handleChangeCheck = async (checked, item_id) => { // Se actualizar la lista de status cfdi a la que tiene acceso el rol seleccionado
        if (checked) {  // Se agrega la relació
            setItemsSel([...items_sel, item_id]);
        } else {        // Se elimina la relació
            setItemsSel(items_sel.filter(element => element !== item_id));
        }
    }

    const getChecked = (item_id) => {       // Obtiene si la partida ha sido facturada para deshabilitarla
        let response = false;
        let pos = _.findIndex(items, { id: item_id });      // Se busca la partida
        if (pos !== -1) {
            let invoice_id = items[pos].invoice_id;         // Se obtiene el ID de la factura
            if (invoice_id) response = true;                // Si no hay significa que no ha sido facturada
        }
        return response;
    }

    const handleChange = (event) => {   // Método para analizar los cambios en los campos de la orden de compra
        let { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }

    const handleSubmit = () => {    // Se ejecuta el método según el tipo de operación, es decir, actualización o creación
        if (items.length > 0) {
            props.type === 'add' ? addPurchaseOrder() : updateOrder();
        } else {
            setToast({ toast, title: t(`${prefix}.warning-sp.title`), description: t(`${prefix}.warning-sp.description`), status: 'warning' });
        }

    }

    const addPurchaseOrder = async () => {  // Método para agregar una nueva orden de compra
        console.log("add purchase order..");
        let response = await storePurchaseOrder({ headers, data: { ...form, fibra_id, folio } });
        if (response) {
            await createOrUpdateItems(response.data.id); // Se actualizan las partidas
            Swal.fire(t(`${prefix}.swal-succ-cre.title`), t(`${prefix}.swal-succ-cre.description`), 'success');
            update(); onClose();
        } else {
            setToast({ toast, title: t(`${prefix}.warning-di.title`), description: t(`${prefix}.warning-di.description`), status: 'warning' });
        }
    }

    const updateOrder = async () => {   // Actualizar orden de compra
        let response = await updatePurchaseOrder({ headers, purchase_order_id, data: form });
        // console.log("update order...", response);
        if (response) {
            await createOrUpdateItems(purchase_order_id); // Se actualizan las partidas
            Swal.fire(t(`${prefix}.swal-succ-upd.title`), t(`${prefix}.swal-succ-upd.description`), 'success');
            update(); onClose();
        }
    }

    const createOrUpdateItems = (id) => {      // Método para actualizar las partidas
        items_destroy.forEach(async (_item) => {    // Elimina las partidas del array de items_destroy
            await destroyPurchaseOrderItem({ headers, item_id: _item });
        })
        items.forEach(async (_item, _index) => {    // Agrega las partidas nuevas
            if (!_item.id) {    // Si la partida tiene ID significa que ya existe en la base de datos
                let { description, total, unit_price, quantity } = _item;
                let response = await storePurchaseOrderItem({ headers, data: { description, unit_price, quantity, total, purchase_order_id: id } });
                // console.log("response partida: ", response);
            }
        });
    }
    // Obtiene el folio de las órdenes de compra nuevas 
    const getFolio = () => { setFolio(`PUO_${new Date().getFullYear() - 2000}_${new Date().getTime()}`); }

    const changeItem = (e) => { // Listener para obtener los cambios del formulario de nueva partida
        let { name, value } = e.target;
        setItem({ ...item, [name]: value });
    }

    const addItem = () => { // Agregar nueva partida al array items y se limpian los datos en memoria
        let { description, unit_price, quantity } = item;
        let total = unit_price * quantity;
        if (description && unit_price && quantity) {
            setItems([...items, { index: items.length + 1, description, quantity, unit_price, total }]);
            setItem({ description: '', total: '', quantity: '', unit_price: '' });
        }
    }

    const removeItem = (i) => {
        // Se tiene Id significa que está registrado y se agrega al array de items a eliminar
        if (i.id) setItemsDestroy([...items_destroy, i.id]);
        // Se actualiza el estado, eliminando el item
        let its = items.filter(_item => _item.index !== i.index);
        // Se actualizan los index 
        let new_items = [];
        its.map((_item, _index) => { new_items.push({ ..._item, index: _index + 1 }); });
        setItems(new_items);
    }

    const updateTotal = () => { // Método para actualizar el total de la partida
        let total = 0;
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);
        items.forEach((_item) => { total += parseFloat(_item.total); })
        setAmount(numberFormat.format(total));
        setForm({ ...form, amount: total });
    }

    // CARGAR FACTURAS
    const handleLoadFile = (e) => {
        let { name } = e.target;
        setFiles(prevState => ({ ...prevState, [name]: e.target.files[0] }));
    }

    const addInvoice = async () => {
        setLoadingUpload(true);
        if (files.xml && files.pdf) {
            let params = {
                fibra_id,
                user_id,
                cfdi_status_id: 1 // Revisar esta parte 
            };
            let data = new FormData();
            data.append('xml', files.xml);
            data.append('pdf', files.pdf);
            let response = await uploadInvoice({ headers, data, params });
            // console.log("REsponse : ", response);
            if (response.status === 201) {
                // Marcar las partidas como facturadas
                const invoice_id = response.data.data.id;
                items_sel.map(async (item) => {
                    // console.log("item updateds: ", item);
                    await updatePurchaseOrderItem({ headers, data: { invoice_id }, item_id: item });
                });

                setInvoice(response.data.data);     // Guardar datos para mostrar información
                setSuccessfull(true);               // Indicar que la carga se ha realizado de manera satisfactoria
                Swal.fire(t(`${prefix}.swal-succ-add.title`), t(`${prefix}.swal-succ-add.description`), 'success');
            } else {
                Swal.fire(t(`${prefix}.swal-err-xml.title`), t(`${prefix}.swal-err-xml.description`), 'error');
            }
        } else {
            Swal.fire(t(`${prefix}.swal-err-load.title`), t(`${prefix}.swal-err.load.description`), 'error');
        }
        setLoadingUpload(false);
    }

    return (
        <ModalComp
            title={`${title}`}
            size='xl'
            onClose={onClose}
            body={
                <Fragment>
                    {
                        successfull ?
                            <FormInvoice data={invoice} /> :
                            !successfull && loadingUpload ? <SkeletonForm rows={5} cols={3} /> : <>

                                <Divider orientation='left'><Heading size='sm'>{t(`${p_data}.general-data`)}</Heading></Divider>
                                {/* <Row className="mb-3">
                                    <FormIcon title={t(`${p_data}.folio`)} />
                                    <FormIcon title={t(`${p_data}.provider`)} />
                                    <FormIcon title={t(`${p_data}.amount`)} />
                                    <FormIcon title={t(`${p_data}.end-date`)} />
                                </Row> */}
                                <Row className='mb-3'>
                                    <Col>
                                        <FloatingLabel label={t(`${p_data}.folio`)}>
                                            <Form.Control type="text" placeholder={t(`${p_data}.folio`)} name='folio' defaultValue={folio} value={folio} readOnly />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label={t(`${p_data}.provider`)}>
                                            <FormSelect name='user_id' onChange={handleChange} value={po?.user_id}
                                                disabled={type === 'provider' ? true : false} required>
                                                <option value={-1}>{t(`${p_data}.select-provider`)}</option>
                                                {
                                                    providers && providers.map((prov, index) =>
                                                        <option key={index} value={prov.id}>{prov.name}</option>
                                                    )
                                                }
                                            </FormSelect>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label={t(`${p_data}.amount`)}>
                                            <Form.Control type="text" name='amount' defaultValue={purchase_order?.amount} value={amount}
                                                disabled={type === 'provider' ? true : false} required />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label={t(`${p_data}.end-date`)}>
                                            <Form.Control type="date" name='ended_date' onChange={handleChange} defaultValue={purchase_order?.ended_date}
                                                disabled={type === 'provider' ? true : false} required />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FloatingLabel label={t(`${p_data}.description`)}>
                                            <Form.Control as='textarea' style={{ height: '5em' }} name='description' defaultValue={purchase_order?.description} onChange={handleChange}
                                                disabled={type === 'provider' ? true : false} required />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Divider orientation='left'><Heading size='sm'>{t(`${p_data}.items`)}</Heading></Divider>
                                {
                                    type !== 'provider' ?
                                        <Row className='mb-3'>
                                            <FormIcon title={t(`${p_data}.item-description`)} name='description' defaultValue={item.description} value={item.description} handleChange={changeItem} />
                                            <FormIcon type='number' name='quantity' title={t(`${p_data}.item-quantity`)} defaultValue={item.quantity} value={item.quantity} handleChange={changeItem} />
                                            <FormIcon type='number' name='unit_price' title={t(`${p_data}.item-unit-price`)} icon='$' defaultValue={item.unit_price} value={item.unit_price} handleChange={changeItem} />
                                            <FormIcon type='number' name='total' title={t(`${p_data}.item-subtotal`)} icon='$' defaultValue={item.total} value={item.quantity * item.unit_price} handleChange={changeItem} />
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <ButtonIcon name={t(`${p_data}.button-add-item`)} icon={faPlus} variant='outline-success' onClick={addItem} />
                                            </Col>
                                        </Row> : null
                                }

                                <Row className='mb-3'>
                                    <Col>
                                        <TableComp
                                            headers={[
                                                '#',
                                                t(`${p_data}.item-description`),
                                                t(`${p_data}.item-quantity`),
                                                t(`${p_data}.item-unit-price`),
                                                t(`${p_data}.item-subtotal`),
                                                t(`${p_data}.item-actions`)
                                            ]}
                                            keys={['index', 'description', 'quantity', 'unit_price', 'total']}
                                            body={items}
                                            currency={[{ index: 3 }, { index: 4 }]}
                                            actions={
                                                type === 'provider' ?
                                                    {
                                                        checkedChildren: t(`${p_data}.checked`), unCheckedChildren: t(`${p_data}.unchecked`),
                                                        onChange: handleChangeCheck,
                                                        getChecked, getDisabled: getChecked
                                                    } : [{
                                                        icon: faTrashAlt, variant: 'outline-danger',
                                                        tooltip: t(`purchase-order.tooltips.item-del`), handleClick: removeItem,
                                                        disabled: type === 'provider' ? true : false
                                                    }]
                                            }
                                            type={type === 'provider' ? 'switch' : ''}
                                            loading={loading}
                                        />
                                    </Col>
                                </Row>
                                {
                                    type === 'provider' && items_sel.length > 0 &&
                                    // <FormFilesUpload
                                    //     handleLoadFile={handleLoadFile}
                                    // />
                                    <Row>
                                        <Divider orientation='left'><Heading size='sm'>{t(`${p_data}.add-invoice`)}</Heading></Divider>
                                        <Form.Group className="mb-3" as={Col}>
                                            <Form.Label>{t(`${p_data}.load-xml`)}</Form.Label>
                                            <Form.Control type="file" name="xml" onChange={handleLoadFile} accept="text/xml" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col}>
                                            <Form.Label>{t(`${p_data}.load-pdf`)}</Form.Label>
                                            <Form.Control type="file" name="pdf" onChange={handleLoadFile} accept="application/pdf" />
                                        </Form.Group>
                                    </Row>
                                }
                            </>
                    }
                </Fragment>}
            footer={<Fragment>
                <ButtonIcon variant="outline-secondary" onClick={onClose} name={t(`${p_data}.button-close`)} icon={faArrowLeft} />
                {
                    type === 'provider' ?
                        items_sel.length > 0 && !successfull && <ButtonIcon name={t(`${p_data}.button-add`)} icon={faSave} onClick={addInvoice} /> :
                        <ButtonIcon variant="outline-primary" name={t(`${p_data}.button-save`)} onClick={handleSubmit} icon={faSave} />
                }
            </Fragment>}
        />
    );
}

export default ModalPurchaseOrder;