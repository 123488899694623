import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { indexBusinessName } from "../../../api/Cfdi/BusinessNames";
import FlexButton from "../../Layouts/FlexButton";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table"
import NewBusinessName from "./NewBusinessName";
import UpdateBusinessName from "./UpdateBusinessName";

const BusinessName = (props) => {
    const { headers, fibra_id } = props;
    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [businessNames, setBusinessNames] = useState([]);
    const [bnSel, setBnSel] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation('bulk_download');

    useEffect(() => {
        getBusinessNames();
    }, [page, pageSize]);

    const getBusinessNames = async () => {
        setLoading(true);
        let response = await indexBusinessName({ headers, fibra_id, page, pageSize });
        setBusinessNames(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const openOrCloseAdd = () => setModalAdd(!modalAdd);
    const openOrCloseUpdate = () => setModalUpdate(!modalUpdate);

    const updateFiel = (item) => {
        setBnSel(item);
        openOrCloseUpdate();
    }

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#', 
                    t('business-name-table.name'),
                    t('business-name-table.tax-id'),
                    t('business-name-table.created-at'),
                    t('business-name-table.actions'),
                ]}
                keys={['#', 'name', 'rfc', 'created_at']}
                body={businessNames}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faFileUpload, tooltip: t('business-name-table.tt-update'), variant: 'outline-success', handleClick: updateFiel }
                ]}
            />
            <PaginationComp current={page} onChange={changePage} totalItems={totalItems} />
            {/* Agregar nueva razón social */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrCloseAdd} />
            {
                modalAdd && <NewBusinessName
                    onClose={openOrCloseAdd}
                    headers={headers}
                    update={getBusinessNames}
                    fibra_id={fibra_id}
                />
            }
            {/* Actualizar archivos CER y KEy de la FIEL */}
            {
                modalUpdate && <UpdateBusinessName
                    onClose={openOrCloseUpdate}
                    headers={headers}
                    business_name_id={bnSel.id}
                />
            }
        </Fragment>
    )
}

export default BusinessName