//Acciones de Sesión
export const OPEN_SESSION = "OPEN_SESSION";
export const DELETE_COOKIES = "DELETE_COOKIES";
export const CLOSE_SESSION = "CLOSE_SESSION";
export const SET_ROLE_SELECTED = "SET_ROLE_SELECTED";
export const SET_MODULES = "SET_MODULES";

export const SET_USERS = 'SET_USERS';

export const SET_CFDI_STATUSES = 'SET_CFDI_STATUSES';

export const SET_CHAT_ROOM_ID = 'SET_CHAT_ROOM_ID';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';