import Documents from './fragments/Documents';
import Users from './fragments/Users';
import Movements from './fragments/Movements';
import Statuses from './fragments/Statuses';
import TabsComp from '../Layouts/Tabs';
import RepseMatrix from './fragments/Main/Index';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { indexRepseUsers } from '../../api/Repse/RepseUsers';
import { indexRepseStatuses } from '../../api/Repse/RepseStatuses';
import { useTranslation } from 'react-i18next';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';

const RepseAdmin = () => {

    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [providers, setProviders] = useState([]);             // Proveedores REPSE
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                        // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);               // Cantidad de registros por página

    const [statuses, setStatuses] = useState([]);
    const [t, i18n] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/repse-admin' });
        getStatuses();
    }, []);

    useEffect(() => {
        getProviders();
    }, [pageSize, page]);

    const getStatuses = async () => {
        let response = await indexRepseStatuses({ headers, fibra_id });
        setStatuses(response.data);
    }
    const getProviders = async () => {
        setLoading(true);
        let response = await indexRepseUsers({ headers, fibra_id, page, pageSize });
        setProviders(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    // Pagination
    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    return (
        <Fragment>
            <TabsComp
                tablist={[
                    t('repse.tabs.main'),
                    t('repse.tabs.providers'),
                    t('repse.tabs.status'),
                    t('repse.tabs.documents'),
                    t('repse.tabs.history')
                ]}
                tabpanels={[
                    <RepseMatrix                    //Matriz de estados de documentos REPSE
                        headers={headers}
                        fibra_id={login.fibra.id}
                        changePage={changePage}
                        page={page}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        loading={loading}
                        providers={providers}
                        statuses={statuses}
                        update={getProviders}
                    />,
                    <Users                          // Proveedores REPSE actuales
                        changePage={changePage}
                        page={page}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        loading={loading}
                        providers={providers}
                        update={getProviders}
                    />,
                    <Statuses />,       // Estados en los que puede estar un usuario con respecto a la documentación REPSE
                    <Documents />,      // Documentos que se solicitan (varian en base al tipo de persona)
                    <Movements />       // Historial de movimiento realizados por los proveedores REPSE
                ]}
            />
        </Fragment>
    );
}

export default RepseAdmin;