import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Mailbox from "./fragments/Mailbox"

const MailboxClient = () => {
    const store = useStore();
    const { fibra, role_selected, user, token } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };

    const [mailbox, setMailbox] = useState([]);             // Lista de CFDi's
    const [invoice, setInvoice] = useState(null);           // Factura seleccionada para mostrar en el modal
    // Paginación
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [cfdi_status_id, setCfdiStatusId] = useState(null);
    const [string, setString] = useState('');                   // Cadena para hallar coincidencias
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mailbox/client' });
    }, []);

    useEffect(() => {
        getMailbox();
    }, [page, pageSize, cfdi_status_id]);

    const getMailbox = async () => {
        setLoading(true);
        let params = { headers, page, pageSize, string };
        if (cfdi_status_id) params.cfdi_status_id = cfdi_status_id;
        let response = await getInvoices({
            ...params, fibra_id: fibra.id,
            role_id: role_selected.id, user_id: user.id
        });
        setMailbox(response.data);
        if (response.totalItems) setTotalItems(response.totalItems);
        setLoading(false);
    }
    // Buscar coincidencias de cadena
    const searchInvoice = () => {
        getMailbox();
    }
    return (
        <Mailbox
            mailbox={mailbox}                   // Colección de registros obtenidos
            loading={loading}                   // Indicador de carga de registros de facturas
            invoice={invoice}                   // Factura seleccionada
            setInvoice={setInvoice}             // Factura seleccionada
            setCfdiStatusId={setCfdiStatusId}   // Guardar el estado de CFDi para actualizar
            page={page}                         // Página actual
            setPage={setPage}                   // Método para cambiar la página
            pageSize={pageSize}                 // Tamaño de la página
            setPageSize={setPageSize}           // Método para cambiar el tamaño de la página
            totalItems={totalItems}             // Total de registros existentes
            tactions={[                          // Botones que son visibles para el proveedor
            ]}
            string={string}
            setString={setString}
            searchInvoice={searchInvoice}
        />
    );
}

export default MailboxClient;