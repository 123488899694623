import { Box, FormLabel, Input, Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DrawerComp from '../../Layouts/Drawer';

const MailboxFilters = (props) => {
    const {
        cfdi_statuses,
        filters,
        setFilters,
        searchInvoice
    } = props;
    const [t, i18n] = useTranslation('global');
    const p = 'mailbox.filters';

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }
    return (
        <DrawerComp title={t(`${p}.title`)} searchByFilters={searchInvoice}>
            {/* Estados CFDI */}
            <Box className='mb-3'>
                <FormLabel>{t('mailbox.filters.select')}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='cfdi_status_id' value={filters?.cfdi_status_id}>
                    <option value={-1}>{t('mailbox.filters.all')}</option>
                    {
                        cfdi_statuses?.map((item, index_i) =>
                            <option key={index_i} value={item.id}>{item.name}</option>
                        )
                    }
                </Select>
            </Box>
            {/* Folio */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.folio`)}</FormLabel>
                <Input placeholder={t(`${p}.folio-ph`)} name='folio' value={filters?.folio} onChange={handleChange} />
            </Box>
            {/* UUID */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.uuid`)}</FormLabel>
                <Input placeholder={t(`${p}.uuid-ph`)} name='uuid' value={filters?.uuid} onChange={handleChange} />
            </Box>
            {/* RFC emisor */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.rfc-issuing`)}</FormLabel>
                <Input placeholder={t(`${p}.rfc-issuing-ph`)} name='rfc_issuing' value={filters?.rfc_issuing} onChange={handleChange} />
            </Box>
            {/* RFC receptor */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.rfc-receptor`)}</FormLabel>
                <Input placeholder={t(`${p}.rfc-receptor-ph`)} name='rfc_receptor' value={filters?.rfc_receptor} onChange={handleChange} />
            </Box>
            {/* Tipo de Moneda */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.currency`)}</FormLabel>
                <Input placeholder={t(`${p}.currency-ph`)} name='currency' value={filters?.currency} onChange={handleChange} />
            </Box>
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.start-date`)}</FormLabel>
                <Input type='date' name='start_date' value={filters?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.end-date`)}</FormLabel>
                <Input type='date' name='end_date' value={filters?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

export default MailboxFilters