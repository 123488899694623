import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react'
import { useEffect } from 'react';
import 'moment/locale/es';
const moment = require('moment')

const Message = (props) => {
    const { alignMsg, msg } = props;

    useEffect(() => {
        moment.locale('es-MX');
    }, []);
    return (
        <Flex className='mb-2' justifyContent={`flex-${alignMsg}`} >
            <Box maxW='30vw' minW='15vw' bgColor={alignMsg === 'end' ? '#eeffde' : '#fff'} className='p-2' borderRadius='lg' boxShadow='xl'>
                <Heading size='xs'>{msg.user.first_name} {msg.user.last_name}</Heading>
                <Text color='gray.600' size='xs'>{msg.message}</Text>
                <div className='d-flex justify-content-end mt-0 pt-0'>
                    <Text fontSize='13px' color='gray.400'>
                        {moment(msg.created_at).startOf('second').fromNow()}
                    </Text>
                </div>
            </Box>
        </Flex>
    )
}

export default Message;